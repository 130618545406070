<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Blog Details
            <v-spacer></v-spacer>
          </v-card-title>
          <v-layout wrap pb-10>
            <v-col cols="12" sm="12" md="12">
              <div id="app">
                <vue-editor v-model="user.text"></vue-editor>
              </div>
            </v-col>
            <v-col cols="12" sm="8" md="8">
              <v-text-field
                v-model="user.title"
                label=" Title"
                outlined
                :rules="Rules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="user.blogdate"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="user.blogdate"
                    label="Select Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="user.blogdate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(user.blogdate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <ImageComp
                :singleImage="user.image"
                :pageId="editingitem._id"
                @stepper="winStepper"
                :height="'804'"
                :width="'1195'"
                :heading="'Upload Blog Image (Aspect Ratio: 3/2)'"
                :componentType="'blogImage'"
              />
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <ImageComp
                :singleImage="user.topImage"
                :pageId="editingitem._id"
                @stepper="winStepper"
                :height="'600'"
                :width="'2500'"
                :heading="'Upload Blog Top Image (Aspect Ratio: 4/1)'"
                :componentType="'blogCoverImage'"
              />
            </v-col>
          </v-layout>
          <v-layout pt-5 pb-5 wrap justify-end>
            <v-flex xs6 md2>
              <v-btn
                block
                tile
                color="green"
                dark
                :ripple="false"
                style="text-transform: none"
                depressed
                @click="edit()"
              >
                Save Changes
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import ImageComp from "@/components/commonComponents/singleImage";
export default {
  props: ["id"],
  components: {
    ImageComp,
    VueEditor,
  },
  data() {
    return {
      ServerError: false,
      widthOfCard: "250px",
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      caption: "",
      title: "",
      blogImage: null,
      blogCoverImage: null,
      showsnackbar: false,
      msg: null,
      pages: 0,
      link: "",
      text: null,
      blogtitle: null,
      blogsubtitle: null,
      blogcaption: null,
      appLoading: false,
      currentPage: 1,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      dialog: false,
      dialog1: false,
      editdialog: false,
      editingitem: [],
      data: [],
      user: [],
      addslider: false,
      imgId: "",
      rules: {
        required: (value) =>
          /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/.test(
            value
          ) || "Must be link",
        min: (v) => v.length >= 10 || "Min 10 characters",
      },
      Rules: [(value) => !!value || "Required."],
      titleRule: [(v) => v.length < 50 || "Min 50 characters"],
    };
  },

  mounted() {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "blogImage") {
        this.blogImage = window_data.selectedFiles;
      }
      if (window_data.type == "blogCoverImage") {
        this.blogCoverImage = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/home/newblog/get",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.user.blogdate = this.user.blogdate.slice(0, 10);
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    edit() {
      this.appLoading = true;
      var user = {};
      user["text"] = this.user.text;
      user["title"] = this.user.title;
      user["blogdate"] = this.user.blogdate;
      user["id"] = this.id;
      axios({
        method: "POST",
        url: "/home/newblog/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            if (this.blogImage) {
              this.uploadImage(this.id);
            }
            if (this.blogCoverImage) this.uploadCoverImage(this.id);
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.link = null;
      this.dialog = false;
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
      this.editingitem.blogdate = this.editingitem.blogdate.slice(0, 10);
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("photo", this.blogImage);

      axios({
        method: "POST",
        url: "/home/newblog/uploadimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadCoverImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("photo", this.blogCoverImage);

      axios({
        method: "POST",
        url: "/home/newblog/uploadtopimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.blogCoverImage = null;
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
